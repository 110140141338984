const state = {
  axiosStatus: null
}

const getters = {
  axiosStatus: (state) => state.axiosStatus? true:false
}

const mutations = {
  updateAxiosStatus(state, status) {
    state.axiosStatus = status;
  }
}

const actions = {
  updateAxiosStatus({commit}, status) {
    commit("updateAxiosStatus", status);
  }
}
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};