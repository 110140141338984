//ikkim FCM
import store from "@/store";

const FCM_SERVER_KEY = 'AAAAuY2vDsQ:APA91bGd-3c7vGaGYQ4BvmwYRnlTcay5Ko_oQuHaSG50fz1DrALiXoxZgGw3lPh2D-P0eC0Naj6VHnLamMI_7faF3ZF6p3Mz2tyZJ545V7kktitNL71w1uSQi9J-lnjiR5QZdO5HPZTG';
//const useMesageService = (!navigator.userAgent.toLowerCase().includes("iphone") && !navigator.userAgent.includes("IMG1000A"))
const IS_APP_DEV = process.env.VUE_APP_DEV == 'true'

//220121 : gateway portal web, iphone일 경우 meessage service를 지원하지 않는다. (Gateway Webview, iPhone에서는 firebase를 지원하지 않아 앱이 실행안됨)

function isAllowFCM () {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|IMG1000A/i.test(navigator.userAgent)) {
      return false;
  } else {
      return true;
  }
}

function subscribeTokenToTopic(token, topic) {

  if(isAllowFCM() /*useMesageService == true*/ && IS_APP_DEV == false) {  
    //console.log("subscribeTokenToTopic");

    if(token == null || topic == null) return;

    let notiReject = store.getters['others/notiReject'];
    if(notiReject == true) {
      console.log('subscribeTokenToTopic: Noti Rejected...');
      return;
    }

    //console.log("token="+token+" topic="+topic);

    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `key=${FCM_SERVER_KEY}`,
      }),
    }).then((response) => {
      if(response.status < 200 || response.status >= 400) {
        console.log(response.status, response);
      }
      //console.log(`${topic} is subscribed(fcm push topic)`);
    }).catch((error) => {
      console.error(error.result);
    });
  }
}

function unSubscribeTokenToTopic(token, topic) {

  if(isAllowFCM() /*useMesageService == true*/) {
  //console.log("unSubscribeTokenToTopic");

    if(token == null || topic == null) return;

    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `key=${FCM_SERVER_KEY}`,
      }),
    }).then((response) => {
      if(response.status < 200 || response.status >= 400) {
        //console.log(response.status, response);
      }
      //console.log(`${topic} is unSubscribed(fcm push topic)`);
    }).catch((error) => {
      console.error(error.result);
    });
  }
}

export default {
    subscribeTokenToTopic,
    unSubscribeTokenToTopic
};