import moment from 'moment';

//Command 정의
const CTRL_GW_ALLCONTROL = 222          //All Device Control (gradeName별로 제어 가능)
const CTRL_GW_AIRCON_MODETEMP = 223     //냉방기 모드/온도 변경
const CTRL_GW_CIRCULATOR_LEVEL = 224    //순환기 단계별 레벨 설정
const CTRL_GW_PURIFIER_LEVEL = 225      //청정기 단계별 레벨 설정
const CTRL_GW_LEDONOFF = 226            //클락 LED 전체 ON/OFF

const DISP_AIR_ONLY = 0;
const DISP_CMONITOR_ONLY = 1;
const DISP_AIR_CMONITOR_BOTH = 2;

const URL_PORTAL2_MISEWATCH = 0;
const URL_SHELTER_MISEWATCH = 1;
const URL_SUMTUR_MISEWATCH = 2;
const URL_KT_MISEWATCH = 3;
const URL_MY_SMART_BUS = 4;
const URL_PORTAL_SMART_BUS = 5;
const URL_PORTAL_SKINNSKIN = 6;
const URL_GANGSEO_SKINNSKIN = 7;

const common = {
    install(Vue) {

        Vue.prototype.$common = {};

        Vue.prototype.$common.CTRL_GW_ALLCONTROL = CTRL_GW_ALLCONTROL
        Vue.prototype.$common.CTRL_GW_AIRCON_MODETEMP = CTRL_GW_AIRCON_MODETEMP
        Vue.prototype.$common.CTRL_GW_CIRCULATOR_LEVEL = CTRL_GW_CIRCULATOR_LEVEL
        Vue.prototype.$common.CTRL_GW_PURIFIER_LEVEL = CTRL_GW_PURIFIER_LEVEL
        Vue.prototype.$common.CTRL_GW_LEDONOFF = CTRL_GW_LEDONOFF

        Vue.prototype.$common.DISP_AIR_ONLY = DISP_AIR_ONLY
        Vue.prototype.$common.DISP_CMONITOR_ONLY = DISP_CMONITOR_ONLY
        Vue.prototype.$common.DISP_AIR_CMONITOR_BOTH = DISP_AIR_CMONITOR_BOTH

        Vue.prototype.$common.URL_PORTAL2_MISEWATCH = URL_PORTAL2_MISEWATCH
        Vue.prototype.$common.URL_SHELTER_MISEWATCH = URL_SHELTER_MISEWATCH
        Vue.prototype.$common.URL_SUMTUR_MISEWATCH = URL_SUMTUR_MISEWATCH
        Vue.prototype.$common.URL_KT_MISEWATCH = URL_KT_MISEWATCH
        Vue.prototype.$common.URL_MY_SMART_BUS = URL_MY_SMART_BUS
        Vue.prototype.$common.URL_PORTAL_SMART_BUS = URL_PORTAL_SMART_BUS
        Vue.prototype.$common.URL_PORTAL_SKINNSKIN = URL_PORTAL_SKINNSKIN
        Vue.prototype.$common.URL_GANGSEO_SKINNSKIN = URL_GANGSEO_SKINNSKIN

        Vue.prototype.$common.isMobile = function () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
                //return true;
            }
        }

        Vue.prototype.$common.dispMode = function (authInfo) {
            if(authInfo.option) {
                return authInfo.option & 0xf;
            }
            return DISP_AIR_ONLY;
        }

        Vue.prototype.$common.setDispMode = function (option, dispMode) {
            return (option & 0xfffffff0) | dispMode;
        }
        
        Vue.prototype.$common.getUrl = function () {
            let url = window.location.toString();
            let ret = {url: 0, banner: "", title: "" };

            if(url.includes("shelter.misewatch.com")) {
                ret.url = URL_SHELTER_MISEWATCH;
                ret.banner = 'shelter';
                ret.title = '미세와치';
            } else if(url.includes("sumtur.misewatch.com")) {
                ret.url = URL_SUMTUR_MISEWATCH;
                ret.banner = 'sumtur';
                ret.title = '숨터';
            } else if(url.includes("my.smart-bus.co.kr")) {
                ret.url = URL_MY_SMART_BUS;
                ret.banner = 'shelter';
                ret.title = '스마트쉘터';
            } else if(url.includes("portal.smart-bus.co.kr")) {
                ret.url = URL_PORTAL_SMART_BUS;
                ret.banner = 'shelter';
                ret.title = '스마트쉘터';
            } else if(url.includes("portal.skinnskin.co.kr")) {
                ret.url = URL_PORTAL_SKINNSKIN;
                ret.banner = 'skinnskin';
                ret.title = '에어클린시스템즈';
            } else if(url.includes("gangseo.skinnskin.co.kr")) {
                ret.url = URL_GANGSEO_SKINNSKIN;
                ret.banner = 'gangseo';
                ret.title = '강서구청';
            } else if(url.includes("kt.misewatch.com")) {
                ret.url = URL_KT_MISEWATCH;
                ret.banner = 'kt';
                ret.title = '미세와치';
            } else {
                ret.url = URL_PORTAL2_MISEWATCH;
                ret.banner = 'school';
                ret.title = '미세와치';
            }

            return ret;
        }   
    
    }
}

export default common;