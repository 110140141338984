import axios from "axios";
import store from "@/store";
import utils from "@/api/http/utils";
import { throttleAdapterEnhancer } from 'axios-extensions';

import router from "@/router";

//const CancelToken = axios.CancelToken;

// let rootRouter = null;
let logoutInProgress = false;
let logoutInMessge = null;

class Timer {
    constructor() {
        this.timerId = -1;
        this.startTime = -1;
    }

    reset() {
        this.stop();
    }

    start(callback, msecs) {
        if (!callback || typeof(callback) !== 'function')
            return;

        this.timerId = setTimeout(() => {
            if (callback && typeof(callback) === 'function')
                callback();
            this.reset();
        }, msecs);
        this.startTime = Date.now();
    }

    extend(callback, msecs) {
        let waitTime = msecs;

        if (!callback || typeof(callback) !== 'function')
            return;

        if (this.timerId !== -1) {
            waitTime += this.remain();
            this.reset();
        }
        this.start(callback, waitTime);
    }

    stop() {
        if (this.timerId >= 0) {
            clearTimeout(this.timerId);
            this.timerId = -1;
            this.startTime = -1;
        }
    }

    remain() {
        if (this.timerId === -1)
            return 0;
        return Date.now() - this.startTime;
    }

    isRunning() {
        return this.timerId !== -1;
    }
}

//let baseUrl = process.env.VUE_APP_SERVER_URL;
let basePath = process.env.VUE_APP_DEV=="true"?'./api':`/`;
let excludeLoading = ['/iot/device', '/statistics/history', '/co2save/statistics_save']; //220203
//let basePath = process.env.VUE_APP_DEV=="true"?'./api':`${location.protocol}//${location.hostname}${process.env.VUE_APP_SERVER_PORT.length>0?':':''}${process.env.VUE_APP_SERVER_PORT}`
// let basePath = process.env.VUE_APP_DEV=="true"?'./api':`${location.protocol}//${location.hostname}${process.env.VUE_APP_SERVER_PORT.length>0?':':''}${process.env.VUE_APP_SERVER_PORT}`
console.info(basePath)
//let baseLocalDevPort = process.env.VUE_APP_CLIENT_PORT;
//let devMode = process.env.VUE_APP_DEV;
const HTTP = axios.create({
    baseURL: basePath,
    timeout: 300000,
    headers: { 'Cache-Control': 'no-cache'},
    adapter: throttleAdapterEnhancer(axios.defaults.adapter, { threshold: 5 * 100 })

    // onUploadProgress: (progressEvent) => {
    //   // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //   // console.log("onUploadProgress", totalLength);
    //   // // if (totalLength !== null) {
    //   // //     this.updateProgressBarValue(Math.round( (progressEvent.loaded * 100) / totalLength ));
    //   // // }
    //   var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
    //   console.log("onUploadProgress", percentCompleted, progressEvent)
    // },
    // onDownloadProgress: (progressEvent) => {
    //   // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //   // console.log("onDownloadProgress", totalLength, progressEvent);
    //   // // if (totalLength !== null) {
    //   // //     this.updateProgressBarValue(Math.round( (progressEvent.loaded * 100) / totalLength ));
    //   // // }
    //   var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
    //   console.log("onDownloadProgress", percentCompleted)
    // }
});
const timer = new Timer();
let requestCount = 0;

// // Add a request interceptor
HTTP.interceptors.request.use(function(config) {
    
    ++requestCount;

    if (requestCount === 1) {
        /* 여기에 로딩 아이콘 display 코드 추가 */

        if(excludeLoading.findIndex(url => url === config.url) < 0) {
            setTimeout(() => {
                if (requestCount > 0) {
                    store.dispatch("common/updateAxiosStatus", true);
                }
            }, 200)
        }
    }

    let accessToken = null;
  
    if(store.getters["auth/accessToken"]){
      accessToken = store.getters["auth/accessToken"];
    }
    if (accessToken && accessToken !== ''){
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    /*    
    if (config.url.lastIndexOf(".html") > 0 && config.url.indexOf("/") > 0) {
        if (location.port === baseLocalDevPort) {
            config.baseURL = baseUrl + "/";
        }
    }

    if (config.method === "get") {
        config.headers.common.Accept = "application/json";
    }
    */

    return config;
    // if(accessToken || config.url.lastIndexOf("login") > 0){
    //   return config;
    // }
    // else{    
    // return {
    //   ...config,
    //   //cancelToken: new CancelToken((cancel) => cancel('Cancel repeated request >>' + config.url))
    // };
    //}
});

// Add a response interceptor
HTTP.interceptors.response.use(
    function(response) {
        --requestCount;
        timer.extend(() => {
            if (requestCount === 0) {
                /* 여기에 로딩 아이콘 숨기는 코드 추가 */
                store.dispatch("common/updateAxiosStatus", false);
            }
        }, 100);
        // Do something with response data
        //console.log(response)
        if (response && response.status == 200) {   
            if (response.config.responseType === "blob") {
                return response;
            } else {
                return utils.rejectIfNotSuccessful(response.data);
            }
        } else {
            return Promise.reject(response);
        }
    },
    function(error) {
        --requestCount;
        timer.extend(() => {
            if (requestCount === 0) {
                /* 여기에 로딩 아이콘 숨기는 코드 추가 */
                store.dispatch("common/updateAxiosStatus", false);
            }
        }, 100);
        if (error.response) {
            if (error.response.status === 401) {
                /* 
                 * 여러개의 HTTP 요청이 발생한 경우, alert 메시지가 여러번 화면에
                 * 나타나는 문제를 해결하기 위해 실제 인증이 되지 않은 경우는 띄우지
                 * 않도록 한다.
                 */
                if (!logoutInProgress) {
                    logoutInProgress = true;

                    /* alert을 띄우기 전에 우선 로그아웃 처리 */
                    store.dispatch('auth/LOGOUT');

                    // logoutInMessge = (strUtils.isBlank(data.msgName))? '로그인 세션이 만료되었습니다.' : data.msgName;
                    logoutInMessge = 0;    
                    /* 
                     * 참고로 여기서 로그인 페이지로 바로 이동시키지 않는 이유는, store.dispatch
                     * 함수가 리턴되었다고 해서 'auth/isAuthenticated'의 값이 바로 변경
                     * 되지는 않기 때문이다.  store와 관련된 모든 함수들은 비동기적으로
                     * 동작하기 때문에, store의 상태가 변하는 타이밍을 정확히 알 수가 없으며,
                     * 여기서 로그인 페이지로 이동하게 될 경우 수많은 에러를 발생시키게 된다.
                     * 그러므로, store.watch를 활용하여 'auth/isAuthenticated'값이 변경
                     * 되는 타이밍에 로그인 페이지로 이동시키도록 한다.
                     */
                }
            }
        }
        // Do something with response error
        return Promise.reject(error);
    }
);

// const authorizationLayerModal = async (logoutInMsg) => {
//   await modal.getModalObj().alert(logoutInMsg, function(){
//     rootRouter.replace({ path: '/login' });
//   });
// };


store.watch((state, getters) => getters['auth/isAuthenticated'], async (val) => {
  if (!val) {
    if(logoutInMessge == 0) { // 401 error일 경우만, navigation duplication error가 발생하여 추가해둠
        router.push('/login');
    }
    logoutInProgress = false;
    logoutInMessge = null;
  }
});


const setHeader = option => {
    
    let config = {
        headers: {
            "Content-type": "application/json"
        }
    };
    if (option) {
        if (option.headers) {
            config = option;
        } else {
            Object.assign(config, option);
        }
    }    
    return config;
};

/*
const retPromise = function() {
    return new Promise(function(resolve, reject) {
        reject(null);
    });
}
*/

const http = {        
    get: function(url, params) {              
        return HTTP.get(url, {params, cache: false})
        //.then(resp => utils.rejectIfNotSuccessful(resp))    
        .catch(err => utils.convertErrorObj(err));
    },
    post: function(url, params, option) {             
        // if(store.getters['auth/isAuthenticated']||rootRouter.history.current.name === "Login"){
        /*if(params){
            params = utils.makeParamData(params);
        }*/
        /*
        if(devMode && devMode ==="true"){
            return HTTP.post(url, params)
            .then(resp => utils.rejectIfNotSuccessful(resp))    
            .catch(err => utils.convertErrorObj(err));  
        } else {
            return HTTP.post(url, params, setHeader(option))
            .then(resp => utils.rejectIfNotSuccessful(resp))    
            .catch(err => utils.convertErrorObj(err));  
        }*/
        return HTTP.post(url, params, setHeader(option))
        //.then(resp => utils.rejectIfNotSuccessful(resp))    
        .catch(err => utils.convertErrorObj(err));  
        
        // }
        // else{
        //   return retPromise();
        // }
    },
    put: function(url, params, option) {
        // params = utils.setCipherdData(params);
        return HTTP.put(url, params, setHeader(option))
        //.then(resp => utils.rejectIfNotSuccessful(resp))    
        .catch(err => utils.convertErrorObj(err));
    },
    delete: function(url, params) {
        return HTTP.delete(url, {params})
        //.then(resp => utils.rejectIfNotSuccessful(resp))    
        .catch(err => utils.convertErrorObj(err));
    },
    all: function(urlArray) {
        return axios.all(urlArray);
    },
    stream: function(urlArray, params, mothod = "get") {
        // console.log("stream");
        return HTTP({
            method: mothod,
            url: urlArray,
            data: params,
            responseType: "blob"
        });
    },
    raw() {
        return HTTP;
    },
    
    // setRootRouter(router) {
    //     rootRouter = router;
    // }
};

export default http;